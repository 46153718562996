import * as React from "react";
import { debounce } from "lodash-es";
import { isMatchingMinCharLimit } from "../../utils/strings/stringUtils";
import { SEARCH_MIN_CHAR_LIMIT } from "../constants/contactsPicker.constants";
import { DEBOUNCE_SEARCH_TIME } from "../constants";

export const useFetchOnTermChange = <TResult>(
  fetch: (term, ...args) => Promise<TResult[]>,
  minCharLimit = SEARCH_MIN_CHAR_LIMIT,
  debounceSearchTime = DEBOUNCE_SEARCH_TIME
): [TResult[], boolean, boolean, string, (term: string, ...args) => void, string] => {
  const [items, setItems] = React.useState<TResult[]>([]);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const onTermChange = debounce((term: string, ...args) => {
    setSearchTerm(term);
    if (term?.length === 0 || !isMatchingMinCharLimit(term, minCharLimit)) {
      setItems([]);
      return;
    }

    setIsLoading(true);
    setHasError(false);
    setErrorMessage("");

    fetch(term, ...args)
      .then(result => {
        setItems(result);
        setIsLoading(false);
      })
      .catch(err => {
        setErrorMessage(err?.message ?? "Failed to fetch");
        setIsLoading(false);
        setHasError(false);
      });
  }, debounceSearchTime);

  return [items, isLoading, hasError, errorMessage, onTermChange, searchTerm];
};
