import * as React from "react";
import { CommonPickerProps, CreateLinkPickerProps } from "../common/typings";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { MaestroSearchScope } from "../common/sgConnectScopes";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { useSingleSelectPicker } from "../common/hooks/useSingleSelectPicker";
import { useFetchOnTermChange } from "../common/hooks/useFetchOnTermChange";
import { Select, SGBSColor } from "@sgbs-ui/core";
import { InvalidFeedback } from "../common/components/InvalidFeedback/InvalidFeedback";
import MaestroSearchBySelect from "../common/components/Maestro/MaestroSearchBySelect";
import { MAESTRO_DEFAULT_SEARCH_BY, MAESTRO_SEARCH_BY_OPTIONS } from "../api/maestro/maestro.const";
import { searchMaestroAccount, searchMaestroAccountByBdrId } from "../api/maestro/maestro.api";
import { IcAccountLevelType, MaestroAccountWidget, ThirdId } from "../api/maestro/maestro.typings";
import { partialRight } from "lodash-es";

interface Props extends CommonPickerProps<MaestroAccountWidget | null>, CreateLinkPickerProps {
  selectedId?: ThirdId | null;
  level?: IcAccountLevelType;
  searchBy?: string;
  searchByOptions?: string[];
  color?: SGBSColor;
}

const MaestroSinglePicker: React.FC<Props> = ({
  selectedId,
  maxResultCount,
  onReady,
  onChange,
  level,
  color = "info",
  searchBy = MAESTRO_DEFAULT_SEARCH_BY,
  searchByOptions = MAESTRO_SEARCH_BY_OPTIONS,
  emitMode,
  ...props
}: Props) => {
  const fetch = useSgConnectFetch(MaestroSearchScope).fetch as FetchFn;
  const [searchByField, setSearchByField] = React.useState<string>(searchBy);
  const pickerId = useGenerateId(props.id);
  const fetchById = React.useCallback(
    (id: ThirdId): Promise<MaestroAccountWidget | null> => {
      if (!id?.bdrId || !id?.level) {
        return Promise.resolve(null);
      }
      return searchMaestroAccountByBdrId(fetch, id);
    },
    [fetch]
  );

  const fetchAccounts = React.useCallback(
    (term: string, searchByField: string): Promise<MaestroAccountWidget[]> =>
      searchMaestroAccount(fetch, term, searchByField, level, maxResultCount),
    [fetch, level, maxResultCount]
  );

  const [selectedContact, , onSelect] = useSingleSelectPicker<MaestroAccountWidget, ThirdId>(
    fetchById,
    emitMode,
    onChange,
    onReady,
    selectedId
  );

  const [accounts, isLoading, hasError, errorMessage, onTermChange] = useFetchOnTermChange<MaestroAccountWidget>(
    fetchAccounts
  );

  const handleOnChangeSearchBy = (fromSearchBy: string): void => {
    setSearchByField(fromSearchBy);
  };

  return (
    <>
      <Select.AsyncSingleSelect<MaestroAccountWidget>
        id={pickerId}
        items={accounts}
        selectedItem={selectedContact}
        idField="id"
        labelField="fullName"
        iconName="business"
        placeholder={props.placeholder}
        errorMessage={errorMessage}
        inError={hasError || props.inError || !!errorMessage}
        onTermChange={partialRight(onTermChange, searchByField)}
        onChange={onSelect}
        createActionText={props.createActionText}
        onCreatClick={props.onCreateLinkClicked}
        showCreateAction={props.createLinkMode !== "none"}
        isLoading={isLoading}
        keepOrder={false}
        color={color}
        size={props.size}
        isOutline={props.outline}
        disabled={props.disabled}
        showToggleButton={false}
        noResultMessage={"No results found. Try another third."}
        inputAppendComponent={
          <MaestroSearchBySelect
            onChange={handleOnChangeSearchBy}
            disabled={props.disabled}
            selected={searchByField}
            values={searchByOptions}
          />
        }
      />
      {props.inError && <InvalidFeedback errorMessage={props.errorMessage} />}
    </>
  );
};

export default MaestroSinglePicker;
