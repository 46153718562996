import { isStringEqual } from "../../utils/strings/stringUtils";
import {
  IcAccountLevelType,
  MaestroAccountDto,
  MaestroAccountLevelType,
  MaestroAccountWidget,
} from "./maestro.typings";
import { MAESTRO_DEFAULT_SEARCH_BY } from "./maestro.const";

type MaestroSearchParams = {
  EntityType?: string;
  EntitySubType?: string;
  pageSize?: number;
  name?: string;
  registrationId?: string;
  registrationType?: string;
};

export const mapToMaestroSearchParameters = (
  registrationId: string,
  registrationType: string,
  maestroLevel: MaestroAccountLevelType | null,
  pageSize?: number
): MaestroSearchParams => {
  const commonParams = {
    "Entity Type": maestroLevel?.type,
    "Entity Sub Type": maestroLevel?.subType,
    pageSize,
  };
  if (isStringEqual(registrationType, MAESTRO_DEFAULT_SEARCH_BY)) {
    return {
      ...commonParams,
      name: registrationId,
    };
  }
  return {
    ...commonParams,
    registrationId,
    registrationType,
  };
};

export const mapToMaestroLevel = (icLevel?: IcAccountLevelType): MaestroAccountLevelType | null => {
  if (isStringEqual(icLevel, "Grp")) {
    return { type: "GROUP" };
  }
  if (isStringEqual(icLevel, "SubGrp")) {
    return { type: "GROUP", subType: "SUB-GROUP" };
  }
  if (isStringEqual(icLevel, "LE")) {
    return { type: "THIRD PARTY" };
  }
  if (isStringEqual(icLevel, "EC")) {
    return { type: "ELEMENTARY THIRD PARTY" };
  }
  return null;
};

export const mapToIcAccountLevel = (
  maestroLevel: MaestroAccountLevelType
): { id: IcAccountLevelType; name: string } | null => {
  if (!maestroLevel?.type) {
    return null;
  }
  if (isStringEqual(maestroLevel.type, "GROUP")) {
    return maestroLevel.subType && !isStringEqual(maestroLevel.subType, "GROUP")
      ? { id: "SubGrp", name: "Legal Sub-Group" }
      : { id: "Grp", name: "Legal Group" };
  }

  if (isStringEqual(maestroLevel.type, "THIRD PARTY")) {
    return { id: "LE", name: "Legal Entity" };
  }

  if (isStringEqual(maestroLevel.type, "ELEMENTARY THIRD PARTY")) {
    return { id: "EC", name: "Legal Elementary Client" };
  }

  return null;
};

export const mapToMaestroAccountWidget = (account: MaestroAccountDto): MaestroAccountWidget => {
  const level = mapToIcAccountLevel({ type: account.type, subType: account.subType })?.id;
  const fullName = `${account?.fullLegalName} (${level}) - BDR ${account.bdrId}`;
  const id = `${account.bdrId}-${level}`;

  return { ...account, id, level, fullName };
};
