import { includes, map } from "lodash-es";
import React from "react";

interface Props {
  selected?: string;
  disabled?: boolean;
  values: string[];
  onChange: (value: string) => void;
}

const MaestroSearchBySelect: React.FC<Props> = ({ selected, disabled, ...props }: Props) => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const [selectedOption, setSelectedOption] = React.useState<string | undefined>(selected);
  const [showDropdown, setShowDropdown] = React.useState<boolean>(false);

  const handleOnChange = (value: string): void => {
    setSelectedOption(value);
    setShowDropdown(false);
    props.onChange(value);
  };
  const handleOnShowDropdown = (): void => {
    if (disabled) {
      return;
    }
    setShowDropdown(!showDropdown);
  };
  const handleCloseDropDown = (event): void => {
    if (!includes(event.composedPath(), ref.current)) {
      setShowDropdown(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleCloseDropDown, false);
    return () => {
      document.removeEventListener("click", handleCloseDropDown);
    };
  }, []);

  React.useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <div ref={ref} className="registration">
      <div className="d-flex flex-nowrap">
        <button
          className={`close-dropdown btn btn-flat-primary bg-transparent px-0 mx-0 text-truncate`}
          onClick={handleOnShowDropdown}
          title={selectedOption}
          style={{ maxWidth: "110px", cursor: disabled ? "default" : "pointer" }}
        >
          {selectedOption}
        </button>
        <div className="mr-2 align-self-center">
          <i className="icon icon-sm text-primary">{showDropdown ? "arrow_drop_up" : "arrow_drop_down"}</i>
        </div>
      </div>
      {!disabled && showDropdown && (
        <div
          className="dropdown-menu dropdown-menu-right show m-0"
          style={{
            position: "absolute",
            right: 0,
            maxHeight: "175px",
            overflowY: "auto",
            maxWidth: "160px",
            zIndex: 2001,
          }}
        >
          {map(props.values, item => (
            <MaestroSearchByOption
              key={item}
              value={item}
              onChange={handleOnChange}
              isActive={selectedOption === item}
            />
          ))}
        </div>
      )}
    </div>
  );
};

interface MaestroSearchByOptionProps {
  isActive: boolean;
  onChange: (value: string) => void;
  value: string;
}

const MaestroSearchByOption: React.FC<MaestroSearchByOptionProps> = ({
  onChange,
  value,
  isActive,
}: MaestroSearchByOptionProps) => {
  const handleOnChange = (e: React.MouseEvent): void => {
    e.preventDefault();
    onChange?.(value);
  };
  return (
    <a
      href=""
      className={`dropdown-item pl-2 text-truncate ${isActive ? "active" : ""}`}
      onClick={handleOnChange}
      title={value}
    >
      {value}
    </a>
  );
};

export default MaestroSearchBySelect;
