export const MAESTRO_DEFAULT_SEARCH_BY = "Name";

export const MAESTRO_SEARCH_BY_OPTIONS = [
  "Name",
  "Account Id",
  "Alert Acronym",
  "Alert Code",
  "AMER Local Reference",
  "BDR Identifier",
  "BDR Mnemonic",
  "BDR-Commercial Identifier",
  "Bloomberg Id",
  "Border",
  "Broadridge equity BPS",
  "Broadridge Fixed Income - IMPACT",
  "Cash Europe application",
  "Client Internal Code",
  "DUN's number",
  "ELIOT Identifier",
  "ELR Identifier",
  "Foreign TIN",
  "FRN Code",
  "GIIN",
  "IC Identifier",
  "ISIN Code",
  "LEI Code",
  "Loan IQ",
  "MAESTRO ID",
  "MARKIT Institution Name",
  "MIE Applications",
  "Morning Star Id",
  "New Edge Reference id",
  "PERLE Id",
  "RCT Identifier",
  "Regulator BIC Code",
  "REMIT-ACER code",
  "SIREN number",
  "SIRET Number",
  "SWIFT Code",
  "SWIFT Id",
  "TIN",
  "Ultimate Parent LEI Code",
  "US Blue Sheets",
];
