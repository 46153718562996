import { IcAccountLevelType, MaestroAccountDto, MaestroAccountWidget, ThirdId } from "./maestro.typings";
import { createRepository } from "../api";
import { FetchFn } from "@sg-widgets/platform-api";
import { mapToMaestroAccountWidget, mapToMaestroLevel, mapToMaestroSearchParameters } from "./maestro.mapper";
import { compact, flatten, map } from "lodash-es";

export const searchMaestroAccount = (
  fetch: FetchFn,
  registrationId: string,
  registrationType: string,
  maestroLevel?: IcAccountLevelType,
  pageSize?: number
): Promise<MaestroAccountWidget[]> => {
  const params = mapToMaestroSearchParameters(
    registrationId,
    registrationType,
    mapToMaestroLevel(maestroLevel),
    pageSize
  );
  return createRepository(fetch)
    .get<{ content: MaestroAccountDto[] }>("/api/v4/entities/search", params)
    .then(result => map(result.content, mapToMaestroAccountWidget));
};

export const searchMaestroAccountByBdrId = (
  fetch: FetchFn,
  bdrLevel: ThirdId
): Promise<MaestroAccountWidget | null> => {
  return searchMaestroAccount(fetch, bdrLevel.bdrId, "BDR Identifier", bdrLevel.level).then(result =>
    result?.length > 0 ? result[0] : null
  );
};

export const searchMaestroAccountByBdrIds = (fetch: FetchFn, bdrLevels: ThirdId[]): Promise<MaestroAccountWidget[]> => {
  const allCalls = map(bdrLevels, bdrLevel => searchMaestroAccountByBdrId(fetch, bdrLevel));
  return Promise.all(allCalls)
    .then(flatten)
    .then(compact);
};
