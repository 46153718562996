import * as React from "react";
import { widgetize, WidgetPropsMapping } from "@sg-widgets/react-core";

import { convertToBoolOrUndefined } from "../utils/strings/stringify";
import WidgetTracker from "../common/components/WidgetTracker";
import MaestroSinglePicker from "./MaestroSinglePicker";
import { WithSGB4 } from "../common/components/WithSGB4";
import { PropMapping } from "@sg-widgets/react-core/lib/props";
import { SGBSColor, SGBSSize } from "@sgbs-ui/core";
import { CommonPickerProps, CreateLinkMode, CreateLinkPickerProps, EmitOnChangeMode } from "../common/typings";
import { EMIT_MODE, MAX_RESULT_COUNT, SIZE } from "../common/constants";
import { MAESTRO_DEFAULT_SEARCH_BY, MAESTRO_SEARCH_BY_OPTIONS } from "../api/maestro/maestro.const";
import { IcAccountLevelType, MaestroAccountWidget } from "../api/maestro/maestro.typings";
import { useCreateLinkClicked } from "../common/hooks/useCreateLinkClicked";

const TAG = "ic-maestro-single-picker";

export interface Props extends CommonPickerProps<MaestroAccountWidget | null, string>, CreateLinkPickerProps {
  selectedId?: { bdrId: string; level: IcAccountLevelType };
  level?: IcAccountLevelType;
  searchBy?: string;
  searchByOptions?: string[];
  color?: SGBSColor;
}

export const MaestroSinglePickerWidget: React.FC<Props> = ({
  id,
  selectedId,
  inError,
  errorMessage,
  onChange,
  onReady,
  maxResultCount = MAX_RESULT_COUNT,
  placeholder = "Search or select a third",
  color,
  size = SIZE,
  searchBy = MAESTRO_DEFAULT_SEARCH_BY,
  searchByOptions = MAESTRO_SEARCH_BY_OPTIONS,
  level,
  disabled,
  outline,
  onCreateLinkClicked,
  createLinkMode = "redirect",
  createActionText,
  emitMode = EMIT_MODE,
}) => {
  const handleOnSelectionChange = (account: MaestroAccountWidget | null): void => {
    onChange?.(account);
  };
  const handleOnCreateLinkClicked = useCreateLinkClicked(createLinkMode, "prospects", onCreateLinkClicked);
  return (
    <WithSGB4>
      <MaestroSinglePicker
        id={id}
        maxResultCount={maxResultCount}
        placeholder={placeholder}
        selectedId={selectedId}
        searchBy={searchBy}
        searchByOptions={searchByOptions}
        level={level}
        size={size}
        color={color}
        createLinkMode={createLinkMode}
        emitMode={emitMode}
        inError={convertToBoolOrUndefined(inError)}
        outline={convertToBoolOrUndefined(outline)}
        disabled={convertToBoolOrUndefined(disabled)}
        createActionText={createActionText}
        errorMessage={errorMessage}
        onChange={handleOnSelectionChange}
        onReady={onReady}
        onCreateLinkClicked={handleOnCreateLinkClicked}
      />
      <WidgetTracker widgetName={TAG} />
    </WithSGB4>
  );
};

widgetize(
  TAG,
  MaestroSinglePickerWidget,
  {
    id: WidgetPropsMapping.asString(),
    maxResultCount: WidgetPropsMapping.asNumber(),
    placeholder: WidgetPropsMapping.asString(),
    disabled: WidgetPropsMapping.asString(),
    outline: WidgetPropsMapping.asString(),
    size: WidgetPropsMapping.asString() as PropMapping<Props, SGBSSize>,
    color: WidgetPropsMapping.asString() as PropMapping<Props, SGBSColor>,
    inError: WidgetPropsMapping.asString(),
    errorMessage: WidgetPropsMapping.asString(),
    createLinkMode: WidgetPropsMapping.asString() as PropMapping<Props, CreateLinkMode>,
    createActionText: WidgetPropsMapping.asString(),
    selectedId: WidgetPropsMapping.asObject(),
    searchBy: WidgetPropsMapping.asString(),
    searchByOptions: WidgetPropsMapping.asObject(),
    emitMode: WidgetPropsMapping.asString() as PropMapping<Props, EmitOnChangeMode>,
    level: WidgetPropsMapping.asString() as PropMapping<Props, IcAccountLevelType>,
    onChange: WidgetPropsMapping.asEventEmitter("changed"),
    onCreateLinkClicked: WidgetPropsMapping.asEventEmitter("create-link-clicked"),
    onReady: WidgetPropsMapping.asEventEmitter("ready"),
  },
  { neverInjectGlobalCss: true }
);
